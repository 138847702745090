import ethereumLogo from 'assets/images/ethereum-logo.png'
import { Chain } from 'graphql/data/__generated__/types-and-hooks'

export enum TokenStandard {
  ERC20,
  ERC721,
}

export interface InteractiveToken {
  name: string
  symbol: string
  address: string
  chain: Chain
  standard: TokenStandard
  color: string
  logoUrl: string
}

export const approvedERC20: InteractiveToken[] = [
  {
    name: 'polygon ecosystem token',
    symbol: 'POL',
    address: '0x0000000000000000000000000000000000001010',
    chain: Chain.Polygon,
    standard: TokenStandard.ERC20,
    color: '#833ADD',
    logoUrl: 'https://app.uniswap.org/static/media/matic-token-icon.efed2ee4e843195b44bf68ffc7439403.svg',
  },
  {
    name: 'Zico DAO',
    symbol: 'ZICO',
    address: '0x486ebcFEe0466Def0302A944Bd6408cD2CB3E806',
    chain: Chain.Polygon,
    standard: TokenStandard.ERC20,
    color: '#ffde59',
    logoUrl:
      'https://polygonscan.com/token/images/zicodao_32.png',
  },
  
]

export const approvedERC721: InteractiveToken[] = [
  // {
  //   name: 'ZicoDAO Collect-2-Earn NFT',
  //   symbol: 'ZicoDAO C2E',
  //   address: '0xbdf2e099a2ea5dc7c5461065c81ec9227b5f931b',
  //   chain: Chain.Polygon,
  //   standard: TokenStandard.ERC721,
  //   color: '#CD237A',
  //   logoUrl:
  //     'https://i.seadn.io/gae/70fhKktz1h38x5pHR-DGxL4zP820_kSe5iVR_dDFXEo-etqbU5H_S-qfnvot7bd2AO7VzsRlgiU1AHYVtLfKaJZx?auto=format&dpr=1&w=384',
  // }
]

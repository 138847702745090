import styled from 'styled-components'

import HolidayUniIcon from './HolidayUniIcon'

// ESLint reports `fill` is missing, whereas it exists on an SVGProps type
export type SVGProps = React.SVGProps<SVGSVGElement> & {
  fill?: string
  height?: string | number
  width?: string | number
  gradientId?: string
  clickable?: boolean
}

export const UniIcon = ({ clickable, ...props }: SVGProps) => (
  <Container clickable={clickable}>
        <svg
        {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 37 37"
      fill="none"
      cursor="pointer"
    >
      <defs>
        <clipPath id="321d463303">
            <path
                d="M 6.53125 8.144531 L 30.453125 8.144531 L 30.453125 28.875 L 6.53125 28.875 Z M 6.53125 8.144531 "
                clip-rule="nonzero" />
        </clipPath>
        <clipPath id="5f51ddd941">
            <path
                d="M 10.242188 11.355469 L 26.789062 11.355469 L 26.789062 25.640625 L 10.242188 25.640625 Z M 10.242188 11.355469 "
                clip-rule="nonzero" />
        </clipPath>
        <clipPath id="198046237f">
            <path d="M 24 11.589844 L 27 11.589844 L 27 13 L 24 13 Z M 24 11.589844 "
                clip-rule="nonzero" />
        </clipPath>
        <clipPath id="270d7a2f9d">
            <path
                d="M 5.214844 11.589844 L 25 11.589844 L 25 14 L 5.214844 14 Z M 5.214844 11.589844 "
                clip-rule="nonzero" />
        </clipPath>
        <clipPath id="6f60f2eac9">
            <path d="M 5.214844 14 L 31.792969 14 L 31.792969 17 L 5.214844 17 Z M 5.214844 14 "
                clip-rule="nonzero" />
        </clipPath>
        <clipPath id="44e45083e4">
            <path d="M 11 17 L 31.792969 17 L 31.792969 20 L 11 20 Z M 11 17 " clip-rule="nonzero" />
        </clipPath>
        <clipPath id="f265bb49e1">
            <path d="M 5.214844 17 L 7 17 L 7 19 L 5.214844 19 Z M 5.214844 17 " clip-rule="nonzero" />
        </clipPath>
        <clipPath id="0f6217639c">
            <path d="M 5.214844 19 L 31.792969 19 L 31.792969 23 L 5.214844 23 Z M 5.214844 19 "
                clip-rule="nonzero" />
        </clipPath>
        <clipPath id="6dbb55a12a">
            <path d="M 30 24 L 31.792969 24 L 31.792969 24.878906 L 30 24.878906 Z M 30 24 "
                clip-rule="nonzero" />
        </clipPath>
        <clipPath id="b1fa4ea70a">
            <path d="M 27 23 L 31 23 L 31 24.878906 L 27 24.878906 Z M 27 23 " clip-rule="nonzero" />
        </clipPath>
        <clipPath id="a3aaedbb9e">
            <path d="M 5.214844 22 L 27 22 L 27 24.878906 L 5.214844 24.878906 Z M 5.214844 22 "
                clip-rule="nonzero" />
        </clipPath>
    </defs>
    <g clip-path="url(#321d463303)">
        <path fill="#ffde59"
            d="M 12.523438 28.863281 L 6.539062 18.503906 L 12.523438 8.144531 L 24.484375 8.144531 L 30.464844 18.503906 L 24.484375 28.863281 Z M 12.523438 28.863281 "
            fill-opacity="1" fill-rule="nonzero" />
    </g>
    <g clip-path="url(#5f51ddd941)">
        <path fill="#ffffff"
            d="M 14.382812 25.640625 L 10.25 18.496094 L 14.382812 11.355469 L 22.648438 11.355469 L 26.785156 18.496094 L 22.648438 25.640625 Z M 14.382812 25.640625 "
            fill-opacity="1" fill-rule="nonzero" />
    </g>
    <path fill="#343434"
        d="M 28.347656 12.640625 C 28.332031 12.640625 28.316406 12.640625 28.300781 12.636719 L 27.4375 12.414062 C 27.339844 12.390625 27.28125 12.292969 27.308594 12.195312 C 27.332031 12.097656 27.429688 12.039062 27.527344 12.0625 L 28.390625 12.285156 C 28.488281 12.308594 28.546875 12.40625 28.523438 12.503906 C 28.5 12.585938 28.425781 12.640625 28.347656 12.640625 Z M 28.347656 12.640625 "
        fill-opacity="1" fill-rule="nonzero" />
    <g clip-path="url(#198046237f)">
        <path fill="#343434"
            d="M 25.96875 12.097656 C 25.960938 12.097656 25.953125 12.097656 25.945312 12.09375 C 25.601562 12.046875 25.234375 12.011719 24.851562 11.988281 C 24.753906 11.984375 24.675781 11.898438 24.679688 11.796875 C 24.6875 11.699219 24.773438 11.625 24.875 11.628906 C 25.265625 11.648438 25.644531 11.6875 25.996094 11.734375 C 26.09375 11.75 26.164062 11.839844 26.148438 11.941406 C 26.136719 12.03125 26.058594 12.097656 25.96875 12.097656 Z M 25.96875 12.097656 "
            fill-opacity="1" fill-rule="nonzero" />
    </g>
    <path fill="#343434"
        d="M 30.625 13.226562 C 30.609375 13.226562 30.59375 13.222656 30.582031 13.21875 L 29.746094 13.003906 C 29.648438 12.980469 29.589844 12.882812 29.613281 12.785156 C 29.636719 12.6875 29.738281 12.628906 29.835938 12.652344 L 30.671875 12.867188 C 30.769531 12.890625 30.828125 12.992188 30.800781 13.089844 C 30.78125 13.171875 30.707031 13.226562 30.625 13.226562 Z M 30.625 13.226562 "
        fill-opacity="1" fill-rule="nonzero" />
    <g clip-path="url(#270d7a2f9d)">
        <path fill="#343434"
            d="M 13.125 13.792969 C 11.996094 13.792969 10.867188 13.679688 10.007812 13.460938 L 5.394531 12.28125 C 5.296875 12.257812 5.238281 12.15625 5.265625 12.058594 C 5.289062 11.960938 5.386719 11.902344 5.484375 11.929688 L 10.097656 13.109375 C 11.769531 13.535156 14.484375 13.535156 16.15625 13.109375 L 20.765625 11.929688 C 21.632812 11.707031 22.785156 11.589844 24.011719 11.597656 C 24.109375 11.601562 24.191406 11.683594 24.191406 11.78125 C 24.191406 11.882812 24.109375 11.960938 24.007812 11.960938 C 22.832031 11.953125 21.679688 12.070312 20.855469 12.28125 L 16.246094 13.460938 C 15.386719 13.679688 14.257812 13.792969 13.125 13.792969 Z M 13.125 13.792969 "
            fill-opacity="1" fill-rule="nonzero" />
    </g>
    <g clip-path="url(#6f60f2eac9)">
        <path fill="#343434"
            d="M 13.125 16.5625 C 11.996094 16.5625 10.867188 16.453125 10.007812 16.234375 L 5.394531 15.054688 C 5.296875 15.027344 5.238281 14.929688 5.265625 14.832031 C 5.289062 14.734375 5.386719 14.675781 5.484375 14.703125 L 10.097656 15.882812 C 11.769531 16.308594 14.484375 16.308594 16.15625 15.882812 L 20.765625 14.703125 C 22.488281 14.261719 25.285156 14.261719 27.003906 14.703125 L 31.617188 15.882812 C 31.714844 15.90625 31.773438 16.003906 31.746094 16.101562 C 31.722656 16.199219 31.625 16.257812 31.527344 16.234375 L 26.914062 15.054688 C 25.246094 14.625 22.527344 14.625 20.855469 15.054688 L 16.246094 16.234375 C 15.386719 16.453125 14.257812 16.5625 13.125 16.5625 Z M 13.125 16.5625 "
            fill-opacity="1" fill-rule="nonzero" />
    </g>
    <g clip-path="url(#44e45083e4)">
        <path fill="#343434"
            d="M 13.121094 19.335938 C 12.738281 19.335938 12.351562 19.324219 11.972656 19.296875 C 11.871094 19.289062 11.796875 19.203125 11.804688 19.101562 C 11.808594 19.003906 11.894531 18.925781 11.996094 18.933594 C 13.484375 19.035156 15.078125 18.929688 16.15625 18.652344 L 20.765625 17.472656 C 22.488281 17.035156 25.285156 17.035156 27.003906 17.472656 L 31.617188 18.652344 C 31.714844 18.679688 31.773438 18.777344 31.746094 18.875 C 31.722656 18.972656 31.625 19.03125 31.527344 19.003906 L 26.914062 17.824219 C 25.246094 17.398438 22.527344 17.398438 20.855469 17.824219 L 16.246094 19.003906 C 15.410156 19.21875 14.277344 19.335938 13.121094 19.335938 Z M 13.121094 19.335938 "
            fill-opacity="1" fill-rule="nonzero" />
    </g>
    <path fill="#343434"
        d="M 9.507812 18.871094 C 9.496094 18.871094 9.480469 18.871094 9.464844 18.867188 L 7.921875 18.472656 C 7.824219 18.445312 7.765625 18.347656 7.789062 18.25 C 7.8125 18.152344 7.914062 18.09375 8.011719 18.121094 L 9.554688 18.515625 C 9.652344 18.539062 9.710938 18.640625 9.683594 18.734375 C 9.664062 18.816406 9.589844 18.871094 9.507812 18.871094 Z M 9.507812 18.871094 "
        fill-opacity="1" fill-rule="nonzero" />
    <g clip-path="url(#f265bb49e1)">
        <path fill="#343434"
            d="M 6.335938 18.058594 C 6.320312 18.058594 6.304688 18.058594 6.289062 18.054688 L 5.394531 17.824219 C 5.296875 17.800781 5.238281 17.703125 5.265625 17.605469 C 5.289062 17.507812 5.386719 17.449219 5.484375 17.472656 L 6.382812 17.703125 C 6.476562 17.726562 6.535156 17.828125 6.511719 17.925781 C 6.492188 18.007812 6.417969 18.058594 6.335938 18.058594 Z M 6.335938 18.058594 "
            fill-opacity="1" fill-rule="nonzero" />
    </g>
    <g clip-path="url(#0f6217639c)">
        <path fill="#343434"
            d="M 13.125 22.109375 C 11.996094 22.109375 10.867188 22 10.007812 21.777344 L 5.394531 20.597656 C 5.296875 20.574219 5.238281 20.472656 5.265625 20.378906 C 5.289062 20.28125 5.386719 20.222656 5.484375 20.246094 L 10.097656 21.425781 C 11.769531 21.855469 14.484375 21.855469 16.15625 21.425781 L 20.765625 20.246094 C 22.488281 19.804688 25.285156 19.804688 27.003906 20.246094 L 31.617188 21.425781 C 31.714844 21.449219 31.773438 21.550781 31.746094 21.648438 C 31.722656 21.746094 31.625 21.804688 31.527344 21.777344 L 26.914062 20.597656 C 25.246094 20.171875 22.527344 20.171875 20.855469 20.597656 L 16.246094 21.777344 C 15.386719 22 14.257812 22.109375 13.125 22.109375 Z M 13.125 22.109375 "
            fill-opacity="1" fill-rule="nonzero" />
    </g>
    <g clip-path="url(#6dbb55a12a)">
        <path fill="#343434"
            d="M 31.570312 24.554688 C 31.554688 24.554688 31.542969 24.554688 31.527344 24.550781 L 30.765625 24.355469 C 30.667969 24.332031 30.609375 24.234375 30.636719 24.136719 C 30.660156 24.039062 30.757812 23.980469 30.855469 24.003906 L 31.617188 24.199219 C 31.714844 24.222656 31.773438 24.324219 31.746094 24.417969 C 31.726562 24.5 31.652344 24.554688 31.570312 24.554688 Z M 31.570312 24.554688 "
            fill-opacity="1" fill-rule="nonzero" />
    </g>
    <g clip-path="url(#b1fa4ea70a)">
        <path fill="#343434"
            d="M 29.921875 24.132812 C 29.90625 24.132812 29.894531 24.132812 29.878906 24.128906 L 27.667969 23.5625 C 27.570312 23.539062 27.511719 23.441406 27.539062 23.34375 C 27.5625 23.246094 27.660156 23.1875 27.757812 23.210938 L 29.96875 23.777344 C 30.066406 23.800781 30.125 23.902344 30.097656 23.996094 C 30.078125 24.078125 30.003906 24.132812 29.921875 24.132812 Z M 29.921875 24.132812 "
            fill-opacity="1" fill-rule="nonzero" />
    </g>
    <g clip-path="url(#a3aaedbb9e)">
        <path fill="#343434"
            d="M 13.125 24.878906 C 11.996094 24.878906 10.867188 24.769531 10.007812 24.550781 L 5.394531 23.371094 C 5.296875 23.34375 5.238281 23.246094 5.265625 23.148438 C 5.289062 23.050781 5.386719 22.992188 5.484375 23.019531 L 10.097656 24.199219 C 11.769531 24.625 14.484375 24.625 16.15625 24.199219 L 20.765625 23.019531 C 22.292969 22.628906 24.675781 22.574219 26.4375 22.894531 C 26.535156 22.914062 26.601562 23.007812 26.582031 23.105469 C 26.566406 23.207031 26.46875 23.273438 26.371094 23.253906 C 24.6875 22.945312 22.316406 22.996094 20.855469 23.371094 L 16.246094 24.550781 C 15.386719 24.769531 14.257812 24.878906 13.125 24.878906 Z M 13.125 24.878906 "
            fill-opacity="1" fill-rule="nonzero" />
    </g>
    </svg>
  </Container>
)

const Container = styled.div<{ clickable?: boolean }>`
  position: relative;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
`
